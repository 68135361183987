import React, {useEffect, useState} from "react"
import Select from "react-select"
import Layout from "../components/layout"

import PopulationTable from "../components/population-table"
import PopulationChange from "../components/population-change"
import PopulationRace from "../components/population-race"
import PopulationAge from "../components/population-age"
import MedianHHIncome from "../components/median-hh-income"
import MedianRent from "../components/median-rent"

import HousingUnitsTable from "../components/housing-units-table"
import HousingUnitsAge from "../components/housing-units-age"
import HousingCosts from "../components/housing-costs"
import HousingUnitsAffordableTable from "../components/housing-units-affordable-table"
import AppealsLine from "../components/appeals-line"
import HousingUnitsOccupancy from "../components/housing-units-occupancy"
import ZillowHomeValues from "../components/zillow-home-values"
import BuildingPermitsTable from "../components/building-permits-table"
import HousingUnitsHeatingFuel from "../components/housing-units-heating-fuel"
import HousingPreservationTable from "../components/housing-preservation-table"

import HousingBedrooms from "../components/housing-bedrooms"
import HousingUnitsByRent from '../components/housing-units-by-rent'

import _ from "lodash"

import HomesSvg from "../images/homes.svg"
import TownSvg from "../images/town.svg"
import AffordabilitySvg from "../images/affordability.svg"

import geos from "../data/geos"
import Housing211Calls from "../components/housing-211"

export default function Home() {

  const validGeos = geos.map(x => x.label)

  const urlGeos = typeof window !== 'undefined'
    ? window.location.href.split('#').pop().split('%20').join(' ').split(',')
      .filter(x => validGeos.indexOf(x) >= 0)
    : []

  var geosDefault = urlGeos.length > 0
    ? urlGeos
    : ['Hartford', 'Hartford County', 'Connecticut']

  const [geosSelected, setGeos] = useState( [] )

  useEffect(() => {
    if (geosSelected.length === 0 && geosDefault.length > 0) {
      setGeos( geosDefault )
    }
  }, [geosDefault, geosSelected])

  return <Layout>

    <h4 className="f4 mb0 pb2 pt2 pl2 bg-near-white">
      Choose towns and counties to compare
    </h4>

    <div style={{position: 'sticky', top: '0px', zIndex: 9999}} className="pv1 ph2 bg-near-white cf">
      <Select
        defaultValue={geosDefault.map( function(x) {
          return {
            value: x,
            label: x
          }
        } )}
        isMulti
        name='geosSelected'
        options={[
          {
            label: 'State',
            options: [{value: 'Connecticut', label: 'Connecticut'}]
          },
          {
            label: 'Counties',
            options: _.filter(_.sortBy(geos, 'value'), function(o) {return o.label.indexOf('County') > 0})
          },
          {
            label: 'Towns',
            options: _.filter(_.sortBy(geos, 'value'), function(o) {return o.label.indexOf('County') < 0 && o.label !== 'Connecticut'})
          }
        ]}
        onChange={function(val) {
          setGeos( val ? val.map(x => x.value) : [] )

          if (val && typeof window !== 'undefined') {
            window.location.href = window.location.href.split('#')[0] + '#' + val.map(x => x.value).join(',')
          }
        }}
        format
      />

      <p className="f5 black-50 mt2 mb1">
        Jump to
          {} <a href="#housing" className="link underline black-50 dim">Housing</a>,
          {} <a href="#affordability" className="link underline black-50 dim">Affordability</a>,
          or <a href="#population" className="link underline black-50 dim">Population</a>.
      </p>

    </div>

    <img src={HomesSvg} className='w-100 mt5' alt="Houses" />

    {geosSelected.length > 0 ?
    <>

      { /* HOUSING */ }
      <h2 id="housing" className="f2 mt0 pt5 mb0 bb bw4" style={{borderColor: '#15397f'}}> Housing </h2>

      <h3 className="f4 mt4 mb0">Units in Structure</h3>
      <HousingUnitsTable geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Bedrooms</h3>
      <HousingBedrooms geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Occupancy Status</h3>
      <HousingUnitsOccupancy geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Age of Units</h3>
      <HousingUnitsAge geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Units by Gross Rent</h3>
      <HousingUnitsByRent geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Building and Demolition Permits</h3>
      <BuildingPermitsTable geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Heating Fuel</h3>
      <HousingUnitsHeatingFuel geos={geosSelected} />


      { /* AFFORDABILITY */ }
      <h2 id="affordability" className="f2 pt5 mb3 bb bw4" style={{borderColor: '#d4e0f0'}}>Affordability</h2>
      <img src={AffordabilitySvg} width='100%' alt="Affordable neighborhood illustration" />

      <h3 className="f4 mt4 mb0">Assisted Housing Units</h3>
      <HousingUnitsAffordableTable geos={geosSelected} />

      <h3 className="f5 mt4 mb0">Percent Affordable Housing Since 2002</h3>
      <AppealsLine geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Median Rent</h3>
      <MedianRent geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Housing Costs</h3>
      <HousingCosts geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Home Values</h3>
      <ZillowHomeValues geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Housing Preservation Units</h3>
      <HousingPreservationTable geos={geosSelected} />


      { /* POPULATION */ }
      <h2 id="population" className="f2 pt5 mb3 bb bw4" style={{borderColor: '#9f3515'}}> Population </h2>
      <img src={TownSvg} className="w-100 mb3" alt="City street with people and cars" />

      <PopulationTable geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Population Change In Past 3 Years</h3>
      <PopulationChange geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Population by Age</h3>
      <PopulationAge geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Population by Race</h3>
      <PopulationRace geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Median Household Income</h3>
      <MedianHHIncome geos={geosSelected} />

      <h3 className="f4 mt4 mb0">2-1-1 Calls</h3>
      <Housing211Calls geos={geosSelected} />

    </>

    : <div className="bg-light-gray pv6 mv5 f3 b tc black-40">
      Choose at least one geography from the dropdown
    </div>
  }

  </Layout>
}
